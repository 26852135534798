.font {
  font-family: 'Mulish', sans-serif;
  
}

.testTimelineElement {
  width: 100%;
  height: 100%;
  background-color: #333;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  
 
  
  
}

.input input {
  width: 30%;
  height: 100%;
  background-color: #dbdbdb;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  color: brand.900;
  border: none;
  outline: none;
  font-size: 18px;
}

.input textarea {
  width: 30%;
  height: 200px;
  background-color: #dbdbdb;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  color: brand.900;
  border: none;
  outline: none;
  font-size: 18px;
}
.input input::placeholder {
  color: rgb(138, 138, 138);
  font-size: 18px;
}
.input textarea::placeholder {
  color: rgb(138, 138, 138);
  font-size: 18px;
}

.input input[type="submit"]:hover {
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.image img {
  height: 350px;
}

.hidden {
  opacity: 0;
  transition: all 1s ease-in-out;
  transform: translateX(-50%);
}

.hiddenRight {
  opacity: 0;
  transition: all 1s ease-in-out;
  transform: translateX(50%);
}

@media(prefer-reduced-motion) {
  .hidden {
    transition: none;
  }
}

@media(prefer-reduced-motion) {
  .hiddenRight {
    transition: none;
  }
}

.show {
  opacity: 1;
  transform: translateX(0);
  
}

.title {
  font-size: 24px;
}

.opacity img {
  left: 0;
  top: 0;

  opacity: 0.55;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 0.40em;
  text-decoration-thickness: 2px;
}

a:not(.title)::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: white;
  transition: width .3s;
}

a:not(.title):hover::after {
  width: 100%;
}



html::-webkit-scrollbar {
  width: 0.5vw;
  height: 0.1vh;
}

html::-webkit-scrollbar-thumb {
  background-color: #333;
}

html::-webkit-scrollbar-thumb:hover {
  background-color: #555
}
